import React from 'react'
import styled from '@emotion/styled'

import { fontSize } from '../Variables/Fonts'
import { mediaQuery } from '../Variables/Contents'

const TagListStyle = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  margin: 0;
`

const TagListItemStyle = styled.li`
  font-size: ${fontSize.xs.sp};

  @media (${mediaQuery.tb}) {
    font-size: ${fontSize.xs.pc};
  }

  &::before {
    content: '#';
  }

  & + & {
    margin-left: 5px;
  }
`

const TagList = ( props ) => {
  return (
    <TagListStyle>
      {props.items &&
        props.items.map((tag, index) => (
          <TagListItemStyle key={index}>
            {tag}
          </TagListItemStyle>
      ))}
    </TagListStyle>
  )
}

export default TagList
