export const fontSize = {
  xs: {
    sp: '.7rem',
    pc: '.8rem',
  },
  sm: {
    sp: '.8rem',
    pc: '1rem',
  },
  md: {
    sp: '1rem',
    pc: '1.4rem',
  },
  lg: {
    sp: '1.2rem',
    pc: '1.6rem',
  },
  xl: {
    sp: '1.4rem',
    pc: '1.7rem',
  },
}
