export const contentPadding = {
  sp: '20px',
  pc: '30px',
}

export const contentWidth = {
  pc: '1060px',
}

export const mediaQuery = {
  tb: 'min-width: 768px',
  pc: 'min-width: 1200px',
}
