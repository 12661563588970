import React from 'react'
import Container from './container'
import Navigation from '../components/navigation'
import Footer from '../components/Footer'
import { Helmet } from 'react-helmet'

class Template extends React.Component {
  render() {
    const { location, children } = this.props
    let header

    let rootPath = `/`
    if (typeof __PREFIX_PATHS__ !== `undefined` && __PREFIX_PATHS__) {
      rootPath = __PATH_PREFIX__ + `/`
    }

    return (
      <Container>
        <Helmet>
          <script async src="https://www.googletagmanager.com/gtag/js?id=G-FQXH1FDXED"></script>
          <script>
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
  
              gtag('config', 'G-FQXH1FDXED');
            `}
          </script>
        </Helmet>
        {/* <Navigation /> */}
        {children}
        <Footer />
      </Container>
    )
  }
}

export default Template
