import React from 'react'
import styled from '@emotion/styled'

import { fontSize } from '../Variables/Fonts'
import { mediaQuery } from '../Variables/Contents'

const DataStyle = styled.p`
  font-size: ${fontSize.xs.sp};
  margin-bottom: 0;

  @media (${mediaQuery.tb}) {
    font-size: ${fontSize.xs.pc};
  }

  &::after {
    content: '...';
    margin-left: 3px;
    margin-right: 3px;
  }
`

const Data = ({ children }) => {
  return (
    <DataStyle>{children}</DataStyle>
  )
}

export default Data
