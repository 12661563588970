import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import { jsx } from '@emotion/react'

import { mediaQuery } from '../Variables/Contents'
import { colors } from '../Variables/Colors'
import { fontSize } from '../Variables/Fonts'
import HeroImageSpPor from '../images/hero_sp_por.png'
import HeroImageSpLan from '../images/hero_sp_lan.png'
import HeroImageSpDetail from '../images/hero_sp_detail.png'
import HeroImagePcIndex from '../images/hero_pc.png'
import HeroImagePcDetail from '../images/hero_pc_detail.png'

const HeroStyled = styled.header`
  align-items: center;
  background-image: url(${props => props.type === 'archive' ? HeroImageSpPor : HeroImageSpDetail});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  height: ${props => props.type === 'archive' ? '100vh' : '150px'};
  width: 100vw;

  @media (orientation: landscape) {
    background-image: url(${HeroImageSpLan});
  }

  @media (${mediaQuery.tb}) {
    background-image: url(${props => props.type === 'archive' ? HeroImagePcIndex : HeroImagePcDetail});
    height: ${props => props.type === 'archive' ? '100vh' : '300px'};
  }
`

const SiteTitle = styled.h1`
  background-color: rgba(0,0,0, .2);
  border-radius: 5px;
  color: ${colors.white};
  font-size: ${props => props.type === 'archive' ? fontSize.lg.sp : fontSize.lg.sp};
  line-height: 1.7;
  margin-bottom: 0;

  @media (orientation: landscape) {
    height: auto;
  }

  @media (${mediaQuery.tb}) {
    font-size: ${props => props.type === 'archive' ? fontSize.xl.pc : fontSize.xl.pc};
  }

  a {
    color: inherit;
    display: block;
    padding: 15px 30px;
    text-decoration: none;
    text-shadow: 2px 2px 2px black;
    transition: all .2s ease-in-out;

    &:hover {
      @media (${mediaQuery.tb}) {
        opacity: .8;
      }
    }

    > span {
      display: ${props => props.type === 'archive' ? 'block' : 'inline-block'};

      @media (orientation: landscape) {
        display: inline-block;
      }
    }
  }
`

const Hero = ({ type }) => {
  return (
    <HeroStyled type={type}>
      <SiteTitle type={type}>
        <Link to="/">
          <span>ぽ</span>
          <span>ち</span>
          <span>ぽ</span>
          <span>ち</span>
          <span>の</span>
          <span>メ</span>
          <span>モ</span>
          <span>帳</span>
        </Link>
      </SiteTitle>
    </HeroStyled>
  )
}

export default Hero
