import React from 'react'
import styled from '@emotion/styled'
import { mediaQuery, contentWidth } from '../Variables/Contents'

const FooterStyled = styled.footer`
  padding: 15px;

  @media (${mediaQuery.tb}) {
    margin: auto;
    max-width: ${contentWidth.pc};
    padding: 30px;
  }

  > p {
    margin-bottom: 0;
    padding-bottom: 10px;
    text-align: center;

    > span {
      vertical-align: text-top;
    }
  }
`

const ProfileList = styled.ul`
  > li {
    > span {
      &:first-of-type {
        font-weight: bold;

        &::after {
          content: "：";
        }
      }
    }
  }
`

const Footer = () => {
  return (
    <FooterStyled>
      <ProfileList>
        <li>
          <span>HN</span>
          <span>ポチポチ</span>
        </li>
        <li>
          <span>仕事</span>
          <span>Web サイトやサービスを作ってます。</span>
        </li>
        <li>
          <span>好きな物・こと</span>
          <span>猫・ゲーム・アニメ・コーヒー</span>
          </li>
        <li>
          <span>このサイトについて</span>
          <span>主に開発用のメモブログですが、特に限定せず様々な事を呟きます。</span>
        </li>
        <li>
          <span>その他</span>
          <span>私ポチポチは、Amazon.co.jpを宣伝しリンクすることによってサイトが紹介料を獲得できる手段を提供することを目的に設定されたアフィリエイトプログラムである、Amazonアソシエイト・プログラムの参加者です。</span><br />
          <span>良心に従い商品レビューをしますが、感想などは参考程度に見て頂ければと思います。</span>
        </li>
      </ProfileList>
      <p>
        <span>&copy;</span> 2020 ぽちぽち<br />
        Illust(C)<a href="http://www.sai-sai-ga-bo.jp/index.html" target="_blank">masayo asano</a>
      </p>
    </FooterStyled>
  )
}

export default Footer
