import React from 'react'
import styled from '@emotion/styled'

import { mediaQuery } from '../Variables/Contents'
import { fontSize } from '../Variables/Fonts'
import headingImg1 from '../images/icon_h1.png'

const HeadingLevel1Style = styled.h1`
  background-image: url(${headingImg1});
  background-position: left top .2em;
  background-repeat: no-repeat;
  background-size: 1.3em auto;
  font-size: ${props => props.type === 'archive' ? fontSize.lg.sp : fontSize.xl.sp};
  line-height: 1.7;
  margin-bottom: 0;
  padding-left: 1.7em;

  @media (${mediaQuery.tb}) {
    font-size: ${props => props.type == 'archive' ? fontSize.md.pc : fontSize.xl.pc};
  }
`

export const HeadingLevel1 = ({ children, type }) => {
  return (
    <HeadingLevel1Style type={type}>{children}</HeadingLevel1Style>
  )
}
