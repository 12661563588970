export const colors = {
  white: '#fff',
  brown: '#b69659',
  gray: {
    base: '#ccc',
    light: '#efefef'
  }
}

export const markdownColors = {
  border: '#edece1',
  backgroundColor: '#fafafa',
  blockquote: '#888',
}
