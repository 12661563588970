import styled from '@emotion/styled'

import { colors, markdownColors } from '../Variables/Colors'
import { contentPadding, mediaQuery } from '../Variables/Contents'
import { fontSize } from '../Variables/Fonts'
import './code-highlight.css'

import headingImg2 from '../images/icon_h2.png'
import headingImg3 from '../images/icon_h3.png'
import headingImg4 from '../images/icon_h4.png'

export const ArticleStyled = styled.article`
  background-color: ${colors.white};
  border-radius: 3px;
  box-shadow: 0 0 15px rgba(0,0,0,.1);
  padding: ${contentPadding.sp};

  @media (${mediaQuery.tb}) {
    padding: ${contentPadding.pc};
  }
`

export const ArticleContentStyled = styled.div`
  padding-top: ${props => props.type === 'archive' ? '15px' : '20px'};

  @media (${mediaQuery.tb}) {
    padding-top: ${props => props.type === 'archive' ? '20px' : contentPadding.pc};
  }

  > *:first-of-type {
    margin-top: 0;
  }

  > *:last-child {
    margin-bottom: 0;
  }

  h2 {
    background-image: url(${headingImg2});
    background-position: left center;
    background-repeat: no-repeat;
    background-size: 1em auto;
    font-size: ${props => props.type === 'archive' ? fontSize.md.sp : fontSize.lg.sp};
    line-height: 1.5;
    padding-left: 1.3em;

    @media (${mediaQuery.tb}) {
      font-size: ${fontSize.lg.pc};
    }

    &:not(:first-of-type) {
      margin-top: 2.3em;
    }
  }

  h3 {
    background-image: url(${headingImg3});
    background-position: left center;
    background-repeat: no-repeat;
    background-size: 1em auto;
    font-size: ${props => props.type === 'archive' ? fontSize.sm.sp : fontSize.md.sp};
    line-height: 1.5;
    margin-top: 2.3em;
    padding-left: 1.3em;

    @media (${mediaQuery.tb}) {
      font-size: ${fontSize.md.pc};
    }
  }

  h4 {
    background-image: url(${headingImg4});
    background-position: left center;
    background-repeat: no-repeat;
    background-size: 1em auto;
    font-size: ${props => props.type === 'archive' ? fontSize.xs.sp : fontSize.sm.sp};
    line-height: 1.5;
    margin-top: 2.3em;
    padding-left: 1.3em;

    @media (${mediaQuery.tb}) {
      font-size: ${fontSize.sm.pc};
    }
  }
  
  p {
    font-size: ${fontSize.sm.sp};
    margin-top: 10px;

    @media (${mediaQuery.tb}) {
      font-size: ${fontSize.sm.pc};
    }

    > img {
      display: table;
      margin: auto;
    }
  }

  a {
    color: ${colors.brown};

    @media (${mediaQuery.tb}) {
      text-decoration: none;
    }
    
    &:hover {
      @media (${mediaQuery.tb}) {
        text-decoration: underline;
      }
    }

    &[target="_blank"] {
      border-left: dashed 1px ${colors.brown};
      border-right: dashed 1px ${colors.brown};
      border-top: dashed 1px ${colors.brown};
      display: table;
      margin-bottom: 1.6em;
      margin-left: auto;
      margin-right: auto;
      padding: 15px;
      position: relative;
      @media (${mediaQuery.tb}) {
        transition: all .2s ease-in-out;
      }

      &:hover {
        @media (${mediaQuery.pc}) {
          opacity: .7;
        }
      }

      &::after {
        background-color: ${colors.brown};
        border-left: solid 1px ${colors.brown};
        border-right: solid 1px ${colors.brown};
        bottom: -1.6em;
        box-sizing: content-box;
        content: "Amazonの公式サイト";
        color: ${colors.white};
        font-size: .8em;
        height: 1.6em;
        left: -1px;
        line-height: 1.6em;
        position: absolute;
        text-align: center;
        width: 100%;
      }

      > img {
        vertical-align: bottom;
      }
    }
  }

  ul, ol {
    font-size: ${fontSize.sm.sp};

    @media (${mediaQuery.tb}) {
      font-size: ${fontSize.sm.pc};
    }

    p {
      margin-bottom: 0;
    }

    > li {
      > ul {
        margin-top: 0;
      }
    }
  }

  table {
    font-size: ${fontSize.sm.sp};

    @media (${mediaQuery.tb}) {
      font-size: ${fontSize.sm.pc};
    }

    th, td {
      border: solid 1px ${markdownColors.border};
      padding: 10px;

      &[align="center"] {
        text-align: center;
      }
    }

    > tbody {
      > tr {
        &:nth-of-type(2n+1) {
          td {
            background-color: ${markdownColors.backgroundColor};
          }
        }
      }
    }
  }

  blockquote {
    margin-bottom: 1.7em;
    margin-top: 1.7em;
    padding-right: 2em;
  }

  code[class*=language-],
  pre[class*=language-] {
    white-space: break-spaces;
  }
`
