import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'

import { contentPadding } from '../Variables/Contents'
import { colors } from '../Variables/Colors'

const CrossIcon = styled.p`
  margin: 0;
  position: fixed;
  right: 30px;
  top: ${contentPadding.sp};

  &::before,
  &::after {
    background-color: black;
    content: '';
    display: block;
    height: 30px;
    position: absolute;
    transform: rotate(45deg);
    z-index: 15;
    width: 3px;
  }

  &::after {
    transform: rotate(-45deg);
  }
`

const Navigation = styled.nav`
  background-color: ${colors.white};
  height: 100vh;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 5;
  width: 80vw;
`

const NavigationMenu = styled.ul`
  list-style-type: none;
  margin: 0;

  > li {
    > a {
      text-decoration: none;
    }
  }
`

export default () => (
  <>
    <CrossIcon />
    <Navigation role="navigation">
      <NavigationMenu>
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to="/about/">About</Link>
        </li>
      </NavigationMenu>
    </Navigation>
  </>
)
