import React from 'react'
import styled from '@emotion/styled'

import TagList from './taglist'
import Data from './Data'

const BlogInfoStyled = styled.div`
  display: flex;
  margin-top: 5px;
`

const BlogInfo = ({ data, items }) => {
  return (
    <BlogInfoStyled>
      <Data>{data}</Data>
      <TagList items={items} />
    </BlogInfoStyled>
  )
}

export default BlogInfo
